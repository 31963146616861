/**
 * measurementMethod will be an enum used for reporting purposes with following possible values
 *  * btr_client
 *  * btr_server
 *  * cod_client
 *  * cod_server
 *  * coi_client
 *  * coi_server
 *  * unknown (default option)
 * cod = Count on download: Creative html/script/tag/assets are requested by the client device.
 * coi = Count on Insertion: Represents counting at time of won bid / ad serve prior to download request by client.
 * btr = Begin to render: Creative assets are loaded and begin to render
 *
 */
export const BEGIN_TO_RENDER_CLIENT = 'btr_client' as const;
export const COUNT_ON_DOWNLOAD_CLIENT = 'cod_client' as const;

export type MEASUREMENT_METHODS = typeof BEGIN_TO_RENDER_CLIENT | typeof COUNT_ON_DOWNLOAD_CLIENT;
