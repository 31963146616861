import { DesktopSFClientAPIImplementation } from './DesktopSFClientAPIImplementation.js';
import { ClientMessageSender } from './clientMessageSender.js';
import type { AdDetails } from '../../@types/adCommon.js';
import { CommonSFClientSetup } from './CommonSFSetup.js';

export class SFClientSetup extends CommonSFClientSetup<DesktopSFClientAPIImplementation> {
    constructor(o: AdDetails, messagePort: MessagePort) {
        const cms = new ClientMessageSender(o, messagePort);
        const mL = {};
        super(
            o,
            messagePort,
            mL,
            cms,
            new DesktopSFClientAPIImplementation(cms, o, mL, () => this.forceRenderFallbackExperience()),
        );
    }
}
