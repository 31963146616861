import { logError } from '@amzn/apejs-instrumentation/src/metrics/logging.js';

// Must be kept in sync with https://tiny.amazon.com/2xu3ne6j/SafeframeCSP
const allowedReferrers: string[] = [
    'amazon.com',
    'amazon.in',
    'amazon.de',
    'amazon.co.jp',
    'amazon.co.uk',
    'amazon.ca',
    'amazon.com.au',
    'amazon.com.be',
    'amazon.com.br',
    'amazon.com.mx',
    'amazon.com.tr',
    'amazon.eg',
    'amazon.es',
    'amazon.fr',
    'amazon.it',
    'amazon.nl',
    'amazon.pl',
    'amazon.sa',
    'amazon.se',
    'amazon.sg',
    'amazon.ae',
    'amazon.co.za',
    'amazon.cn',
    'amazon.dev',
    'boxofficemojo.com',
    'a2z.com',
    'console.harmony.a2z.com',
    'depot.advertising.amazon.dev',
    'harmony.a2z.com',
    'd116tmsy8qpy37.cloudfront.net',
    'd1u3onhraow3iz.cloudfront.net',
    'd1ubtmbgqm1jlf.cloudfront.net',
    'd2dk40qqpfbxei.cloudfront.net',
    'd2jbcalcdp0p85.cloudfront.net',
    'd2y0vsnh7zmbrq.cloudfront.net',
    'd345aeerog89iv.cloudfront.net',
    'imdb.com',
];

const isMatched = (referrerHostName: string, allowedList: string[]): boolean => {
    return allowedList.some((domain) => {
        return referrerHostName.endsWith(domain);
    });
};

export const isReferrerAllowed = (): boolean => {
    const referrer = document.referrer;

    if (!referrer) {
        return false; // No referrer means it's not allowed
    }
    try {
        const url = new URL(referrer);
        return isMatched(url.hostname, allowedReferrers);
    } catch (error) {
        logError('Invalid referrer URL:', referrer);
        return false;
    }
};
