import { creativeReplayCompleteEventName, creativeReplayAttr, creativeReplayId } from './render.js';

type FiredAttributes = 'iscomplete' | 'creativeLoad' | 'creativedomcontentloaded';

// This is so we can tell from the DOM if the creative has triggered
//  This is useful for UI testing and also interactive debugging
export const markScriptAsFired = (attr: FiredAttributes) =>
    document.getElementById(creativeReplayId)?.setAttribute(attr, 'true');

export const waitUntilCreativeScriptsSyncExecuted = () =>
    new Promise<void>((r, reject) => {
        const el = document.getElementById(creativeReplayId);
        if (el?.getAttribute(creativeReplayAttr) === 'true') {
            r();
        } else {
            document.addEventListener(
                creativeReplayCompleteEventName,
                () => {
                    markScriptAsFired(creativeReplayAttr);
                    r();
                },
                { once: true },
            );
            const reallyLongTimeoutJustToGetRTLAToGenerate = 5000;
            setTimeout(() => {
                if (el?.getAttribute(creativeReplayAttr) !== 'true') {
                    reject(
                        new Error(
                            `Creative script replay not detected within ${reallyLongTimeoutJustToGetRTLAToGenerate} timeout`,
                        ),
                    );
                }
            }, reallyLongTimeoutJustToGetRTLAToGenerate);
        }
    });
