/**
 * @desc IAB safeframe API for some limited functionality
 * {@link http://www.iab.net/media/file/SafeFrames_v1.1_final.pdf}
 */

import type { GeometricInformation, ExternalParty } from '@amzn/safe-frame-client';
import type { UpdateViewabilityData, ViewabilityStandard, ViewableInfo } from '@amzn/safe-frame-client-amzn';

// Values with prefix `cache` will be updated by the parent page's sf library code periodically.
let cachedViewability: number | null = null;

let cachedGeom: GeometricInformation | null = null;

let cachedPayload: ViewableInfo | null = null;

let cachedViewabilityStandards: ViewabilityStandard[] | null = null;

let cachedPlayingTime: number | null = null;

export interface SafeFrame {
    ext: ExternalParty;
}

export interface SfViewableInfo {
    viewabilityStandards?(): ViewabilityStandard[];
    payload?(): ViewableInfo;
}
class SfViewableInfoImpl implements SfViewableInfo {
    /**
     * @desc [Internal & External] Gives the viewability standards of SafeFrame container
     */
    viewabilityStandards(): ViewabilityStandard[] {
        cachedViewabilityStandards = cachedViewabilityStandards || [];
        return cachedViewabilityStandards as ViewabilityStandard[];
    }
    /**
     * @desc [Internal & External] Gives the viewability payload of SafeFrame container
     * @returns {Object} ViewabilityPayload - cachedPayload
     */
    payload(): ViewableInfo {
        // TODO Fix this incosistency
        cachedPayload = cachedPayload || ({} as any); //This is probably a bug
        return cachedPayload as ViewableInfo;
    }
}

export const $sfViewableInfo: SfViewableInfo = new SfViewableInfoImpl();

interface PlayingTimeInfo {
    playingTime?: (() => number | null) | null;
}

export const $sf: SafeFrame = {
    ext: {
        /**
         * @desc Gives the inViewPercentage of the SafeFrame container
         * @returns {Number} returns the inView percentage of the SafeFrame container
         */
        inViewPercentage: (): number => {
            // The range of the sf api function
            // is a whole number in [0, 100]
            cachedViewability = cachedViewability || 0;
            return Math.round(100 * cachedViewability);
        },
        /**
         * @desc Gives the geometric dimension and location of SafeFrame container
         */
        geom: (): GeometricInformation | null => {
            cachedGeom = cachedGeom || ({} as any); //Probably another bug
            return cachedGeom;
        },
    },
};

/**
 * @desc Gives the playing time in seconds for a video creative
 * @returns {Number} cachedPlayingTime
 */
const $sfPlayingTimeInfo: PlayingTimeInfo = {
    playingTime: (): number => {
        cachedPlayingTime = cachedPlayingTime || 0;
        return cachedPlayingTime;
    },
};

/**
 * Return the object which includes the APIs for external party.
 */
export const produce = (): SafeFrame => {
    return $sf;
};

export const produceViewableInfo = (): SfViewableInfo => {
    return $sfViewableInfo;
};

export const producePlayingTimeInfo = (): PlayingTimeInfo => {
    return $sfPlayingTimeInfo;
};

export const update = (data: UpdateViewabilityData) => {
    // update cached viewability for synchronous viewability access
    cachedViewability = data?.geom?.self?.iv !== undefined ? data?.geom?.self?.iv : null;
    if (cachedViewability === null) {
        // Problem determining viewability remove functions that expose it
        delete $sf.ext.inViewPercentage;
    }
    cachedGeom = data?.geom !== undefined ? data?.geom : null;
    if (cachedGeom === null) {
        // Problem determining geom remove functions that expose it
        delete $sf.ext.geom;
    }
    cachedPayload = data?.payload !== undefined ? data?.payload : null;
    if (cachedPayload === null) {
        // Problem determining payload remove functions that expose it
        delete $sfViewableInfo.payload;
    }
    cachedViewabilityStandards = data?.viewabilityStandards !== undefined ? data?.viewabilityStandards : null;
    if (cachedViewabilityStandards === null) {
        // Problem determining viewabilityStandards remove functions that expose it
        delete $sfViewableInfo.viewabilityStandards;
    }
};

export const updatePlayingTime = (playingTime: number) => {
    cachedPlayingTime = playingTime;
    if (cachedPlayingTime === null) {
        // Problem determining playingTime remove functions that expose it
        delete $sfPlayingTimeInfo.playingTime;
    }
};
