declare global {
    // eslint-disable-next-line
    interface Window extends WeblabsWindow {}
}

export interface WeblabsWindow {
    renderingWeblabs?: { [key: string]: string };
}

export class Weblab {
    constructor(
        private readonly weblabsWindow: WeblabsWindow,
        private readonly prefix: string,
    ) {}

    isT1 = (): boolean => {
        return this.weblabTreatment() === 't1';
    };

    isT2 = (): boolean => {
        return this.weblabTreatment() === 't2';
    };

    isT3 = (): boolean => {
        return this.weblabTreatment() === 't3';
    };

    isT4 = (): boolean => {
        return this.weblabTreatment() === 't4';
    };

    isT5 = (): boolean => {
        return this.weblabTreatment() === 't5';
    };

    private weblabTreatment = (): string | undefined => {
        return this.weblabsWindow?.renderingWeblabs?.[this.prefix]?.toLowerCase();
    };
}

/*
 * Will increase the amount of errors logged for transparency information missing
 * @link  https://weblab.amazon.com/wl/ADPT_SF_TRANSPARENCY_INFO_MANDATORY_FOR_EU_712921
 */
export const ADPT_SF_TRANSPARENCY_INFO_MANDATORY_FOR_EU = () =>
    new Weblab(window, 'ADPT_SF_TRANSPARENCY_INFO_MANDATORY_FOR_EU_712921');

/*
 * Gonna be used for some general light ads refactorings
 * @link  https://weblab.amazon.com/wl/ADPT_SF_LIGHTADS_REFACTOR_903064
 */
export const ADPT_SF_LIGHTADS_REFACTOR = () => new Weblab(window, 'ADPT_SF_LIGHTADS_REFACTOR_903064');

/*
 * Used for gating IN art event styling
 * @link https://weblab.amazon.com/wl/A2I_HOMEPAGE_CARD_THEMING_360311
 */
export const A2I_HOMEPAGE_CARD_THEMING = () => new Weblab(window, 'A2I_HOMEPAGE_CARD_THEMING_360311');

/*
 * Use for gating timeout from waitUntilElementExists
 * @link
 */
export const ADPT_SF_WAITUNTILELEMENT_TIMEOUT = () => new Weblab(window, 'ADPT_SF_WAITUNTILELEMENT_TIMEOUT_990056');

/*
 * Use for triggering blankAds detection script.
 * @link https://weblab.amazon.com/wl/ADPT_SF_ENABLE_BLANKADS_DETECTION_990833
 */
export const ADPT_SF_ENABLE_BLANKADS_DETECTION = () => new Weblab(window, 'ADPT_SF_ENABLE_BLANKADS_DETECTION_990833');

/*
 * Use for gating btr delay time.
 * @link https://weblab.amazon.com/wl/ADPT_SF_BTR_PIXEL_DELAYTIME_1027666
 */
export const ADPT_SF_BTR_PIXEL_DELAYTIME = () => new Weblab(window, 'ADPT_SF_BTR_PIXEL_DELAYTIME_1027666');

/*
 * Use for triggering sponsored label server side rendering.
 * @link https://weblab.amazon.com/wl/ADPT_SF_SERVER_SIDE_SPONSORED_LABEL_998980
 */
export const ADPT_SF_SPONSORED_LABEL_SERVER_SIDE = () =>
    new Weblab(window, 'ADPT_SF_SERVER_SIDE_SPONSORED_LABEL_998980');

/*
 * Use for gating firing impressions on both COD and BTR.
 * @link https://weblab.amazon.com/wl/ADPT_SF_BTR_PIXEL_LIGHTADS_1064211
 */
export const ADPT_SF_BTR_PIXEL_LIGHTADS_1064211 = () => new Weblab(window, 'ADPT_SF_BTR_PIXEL_LIGHTADS_1064211');

/*
 * Used for sampling metrics logging
 * @link https://weblab.amazon.com/wl/ADPT_SF_METRICS_LOGGING_1100263
 */
export const ADPT_SF_METRICS_LOGGING = () => new Weblab(window, 'ADPT_SF_METRICS_LOGGING_1100263');

/*
 * Use setInterval to check BTR
 * @link https://weblab.amazon.com/wl/ADPT_SF_BTR_SIMPLE_1107281
 */
export const ADPT_SF_BTR_SIMPLE = () => new Weblab(window, 'ADPT_SF_BTR_SIMPLE_1107281');

/*
 * Used for adding support for Fixed Position to SafeFrame viewability tracking
 * @link https://weblab.amazon.com/wl/ADPT_SF_FIXED_POS_INT_OBSERVER_1106762
 *
 * Clean Up Sim
 * @link https://sim.amazon.com/issues/TRUNKS-2782
 */
export const ADPT_SF_FIXED_POS_INT_OBSERVER = () => new Weblab(window, 'ADPT_SF_FIXED_POS_INT_OBSERVER_1106762');
