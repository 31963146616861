/**
 * @desc The object accessible to ad code that interacts with the parent window through postMessage for Desktop.
 */
declare global {
    interface Window {
        dcmads: any;
    }
}

export const initDcm = () => {
    // the below flag is used to prevent DoubleClickManager creatives from blowing up the dom
    // using document.write. The file dcmads.js will run a regex for "4|40004007" against
    // window.dcmads.eids to decide whether it appends creative content using document.write
    // or HTMLElement.appendChild. Solves: https://t.corp.amazon.com/HEDWIGTT-300
    window.dcmads = window.dcmads || {};
    window.dcmads.eids = '4|40004007';
};
