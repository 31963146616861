export const REQUEST_START = 'adfetch:request';
export const PUNT_RETURNED = 'adfetch:punt';
export const ERROR_RETURNED = 'adfetch:error';
export const EMPTY_RESPONSE = 'adfetch:noad';
export const RENDERED_AD = 'adfetch:renderad';
export const RENDER_ERROR = 'adfetch:rendererror';
export const HTTP_ERROR = 'adfetch:httperror';
export const EXCEPTION = 'adfetch:exception';

export type AD_FETCH_COUNTERS =
    | typeof REQUEST_START
    | typeof PUNT_RETURNED
    | typeof ERROR_RETURNED
    | typeof EMPTY_RESPONSE
    | typeof RENDERED_AD
    | typeof RENDER_ERROR
    | typeof HTTP_ERROR
    | typeof EXCEPTION;
