type CsaKey =
    | 'bodyBegin'
    | 'bodyEnd'
    | 'clickToATF'
    | 'criticalFeature'
    | 'loaded'
    | 'viewablelatency:bodyBegin'
    | 'viewablelatency:loaded';

type CsmKey = 'af' | 'bb' | 'be' | 'cf' | 'ld';

const HOST_SCOPE = 'sfImpression' as const;
const LIGHTAD_SCOPE = 'LightAdImpression' as const;
const VIEWABLE_LATENCY = 'viewablelatency' as const;

type CsmMessage = typeof HOST_SCOPE | typeof LIGHTAD_SCOPE | typeof VIEWABLE_LATENCY;

export interface LatencyMetricType {
    readonly csaKey: CsaKey;
    readonly csmKey: CsmKey;
    readonly csmMessage?: CsmMessage;
}

const create = (csaKey: CsaKey, csmKey: CsmKey, csmMessage?: CsmMessage): LatencyMetricType => {
    return { csaKey, csmKey, csmMessage };
};

export const BODY_BEGIN_HOST_SCOPE = create('bodyBegin', 'bb', HOST_SCOPE);
export const BODY_BEGIN_LIGHTAD_SCOPE = create('bodyBegin', 'bb', LIGHTAD_SCOPE);

export const BODY_END = create('bodyEnd', 'be');

export const CLICK_TO_ATF = create('clickToATF', 'af');

export const CRITICAL_FEATURE = create('criticalFeature', 'cf');
export const CRITICAL_FEATURE_HOST_SCOPE = create('criticalFeature', 'cf', HOST_SCOPE);
export const CRITICAL_FEATURE_LIGHTAD_SCOPE = create('criticalFeature', 'cf', LIGHTAD_SCOPE);

export const LOADED = create('loaded', 'ld');
export const LOADED_HOST_SCOPE = create('loaded', 'ld', HOST_SCOPE);
export const LOADED_LIGHTAD_SCOPE = create('loaded', 'ld', LIGHTAD_SCOPE);

export const VIEWABLE_LATENCY_BODY_BEGIN = create('viewablelatency:bodyBegin', 'bb', VIEWABLE_LATENCY);
export const VIEWABLE_LATENCY_LOADED = create('viewablelatency:loaded', 'ld', VIEWABLE_LATENCY);
