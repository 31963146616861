export const IFRAME_INIT = 'adload_iframeinitialized';
export const RENDER_START = 'adload_renderstart';
export const RENDER_END = 'adload_renderend';
export const CREATIVE_WRITTEN = 'adload_creativewritten';
export const IMP = 'impression';
export const BTR = 'btr';
export const UNSERVED_IMP = 'unservedimpression';
export const VIEWABILITY = 'viewability';
export const UNSERVED_VIEWABILITY = 'unservedviewability';
export const MEASURABILITY = 'measurability';

// Host side
export const START = 'adload_start';
export const CALLBACK = 'adload_delayload';

export type AD_LOAD_EVENTS =
    | typeof IFRAME_INIT
    | typeof RENDER_START
    | typeof RENDER_END
    | typeof CREATIVE_WRITTEN
    | typeof IMP
    | typeof UNSERVED_IMP
    | typeof VIEWABILITY
    | typeof UNSERVED_VIEWABILITY
    | typeof MEASURABILITY
    | typeof START
    | typeof CALLBACK;
