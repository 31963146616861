import type { AANReponse } from '../components/aanReponse.js';

// Only exported for mocking
// Not exposed to the client
export const getAanResponse = (slotName: string): AANReponse | undefined => {
    // Support both the DisplayAds and A9 method of getting aanResponse.
    return (
        window?.aanResponse ||
        (window.document.aanResponse ? window.document?.aanResponse[slotName + 'adData'] : undefined)
    );
};
