import { AdResponse, HtmlProviderPort } from '../../domain/htmlProviderPort.js';
import { utf_8_atob } from '../../../components/util.js';

export const createServerHtmlProvider = (
    htmlContentEncoded: string | undefined,
    htmlContentEncodedLength: number | undefined,
): HtmlProviderPort => ({
    async getAd(): Promise<AdResponse> {
        return {
            creative: await getCreative(htmlContentEncoded, htmlContentEncodedLength),
        };
    },
});

const getCreative = async (
    htmlContentEncoded: string | undefined,
    htmlContentEncodedLength: number | undefined,
): Promise<string> => {
    if (!htmlContentEncoded) throw new Error('No html content found on a server side fetch');
    return decodeHtmlContent(htmlContentEncoded, htmlContentEncodedLength);
};

const decodeHtmlContent = (htmlContentEncoded: string, htmlContentEncodedLength: number | undefined): string => {
    // TODO Remove the backwards compatible approach
    const htmlContent = htmlContentEncodedLength ? htmlContentEncoded : utf_8_atob(htmlContentEncoded);
    logIfError(htmlContent, htmlContentEncodedLength);
    return htmlContent;
};

const logIfError = (htmlContent: string, htmlContentEncodedLength: number | undefined): void => {
    if (htmlContentEncodedLength && htmlContent.length !== htmlContentEncodedLength) {
        console.debug(
            `Mismatched expected htmlContentEncoded size. Expected ${htmlContentEncodedLength} but got ${htmlContent.length}`,
        );
    }
};
