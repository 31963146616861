export const REQUEST_START = 'adfetch_request';
export const PUNT_RETURNED = 'adfetch_punt';
export const ERROR_RETURNED = 'adfetch_error';
export const EMPTY_RESPONSE = 'adfetch_noad';
export const RENDERED_AD = 'adfetch_renderad';
export const RENDER_ERROR = 'adfetch_rendererror';
export const HTTP_ERROR = 'adfetch_httperror';
export const EXCEPTION = 'adfetch_exception';

export type AD_FETCH_EVENTS =
    | typeof REQUEST_START
    | typeof PUNT_RETURNED
    | typeof ERROR_RETURNED
    | typeof EMPTY_RESPONSE
    | typeof RENDERED_AD
    | typeof RENDER_ERROR
    | typeof HTTP_ERROR
    | typeof EXCEPTION;
