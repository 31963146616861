import { CRITICAL_FEATURE, LOADED } from '../components/metrics/latency-metric-type.js';
import { CommonApiImplementation } from './CommonApiImplementation.js';

export const disableCookieAccess = () => {
    Object.defineProperty(document, 'cookie', {
        get: () => {
            return '';
        },
        set: () => {
            /** Empty */
        },
    });
};

export const disableGeolocationApi = () => {
    if (window?.navigator?.geolocation) {
        window.navigator.geolocation.watchPosition = (
            _successCallback: PositionCallback,
            _errorCallback?: PositionErrorCallback | null,
            _options?: PositionOptions,
        ): number => {
            return 0; /* Empty */
        };
        window.navigator.geolocation.getCurrentPosition = () => {
            /** Empty */
        };
    }
};

/*
 * Use for cases where safeframe is not supported and
 * error message could not possibly propagate to parent
 * window for proper RTLA logging.
 */

/**
 * @deprecated Use logError
 */
export const errorLog = (msg: string, ex?: Error | string) => {
    /* eslint-disable no-console */
    console.error(msg, ex);
    /* eslint-enable no-console */
};

export const sendCriticalFeatureAndLoaded = (c: CommonApiImplementation) => {
    c.sendLatencyMetric(LOADED);
    c.sendLatencyMetric(CRITICAL_FEATURE);
};
