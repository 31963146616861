// Utility Functions used by both Client and Server
import {} from '@amzn/amazon-ui-page-ts';

export const safeFunctionWrapper = (
    handler: (...safeArgs: any[]) => void,
    errorHandler?: (msg: string, ex?: any) => void,
    errorMessage?: string,
): any => {
    return (...args: any[]) => {
        try {
            handler(...args);
        } catch (ex) {
            errorHandler?.(errorMessage ? errorMessage : 'Error in handler', ex);
        }
    };
};

/**
 * Check if the given mediaType is video
 * @param {String} mediaType - mediaType value from AAX
 * @returns {Boolean} isVideo - True if mediaType is V
 */
export const isVideo = (mediaType: string): boolean => mediaType === 'V';

/* eslint-disable quote-props */
export const ABP_STATUS = {
    '1': 'Enabled',
    '0': 'NotEnabled',
    '-1': 'Unknown',
};

// Below code is for utf_8 compliant versions of atob/btoa
//M DN https://developer.mozilla.org/en-US/docs/Glossary/Base64#solution_2_%E2%80%93_rewriting_atob_and_btoa_using_typedarrays_and_utf-8

export const utf_8_atob = (base64: string): string => b64_to_utf8(base64);
export const utf_8_btoa = (js_text: string): string => utf8_to_b64(js_text);

const base64TestRegexp = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/;
/**
 * @deprecated Remove once GPAT changes that only send text have been rolled out
 */
const isBase64 = (stringToTest: string) => base64TestRegexp.test(stringToTest);
/**
 * @deprecated Remove once GPAT changes that only send text have been rolled out
 */
export const convertToStringIfBase64 = (stringToTest: string) =>
    isBase64(stringToTest) ? utf_8_atob(stringToTest) : stringToTest;

export const convertToBase64IfString = (stringToTest: string) =>
    isBase64(stringToTest) ? stringToTest : utf8_to_b64(stringToTest);

// We use unescape even though not recommended due to performance and code size
const utf8_to_b64 = (utf8: string): string => {
    return btoa(unescape(encodeURIComponent(utf8)));
};

const b64_to_utf8 = (base64: string): string => {
    return decodeURIComponent(escape(atob(base64)));
};

export const isEmptyObject = (obj?: object) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

// Remove when https://issues.amazon.com/issues/V1533734779 is resolved.
export const removeAAXPayloadFromUrl = (url: string): string => {
    if (!url) {
        return url;
    }
    // eslint-disable-next-line no-template-curly-in-string
    return url.split('&c=${AAX_PAYLOAD}').join('');
};
