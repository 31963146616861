// from https://console.harmony.a2z.com/aax-hub/apis/bid-api/xsp#getad-response
export type AAXGetAdResponse = {
    /**
     * @deprecated The field should not be used. It is overflowing. Use adIdStr.
     */
    adId: number;
    adIdStr: string;
    /**
     * @deprecated The field should not be used. It is overflowing. Use creativeIdStr.
     */
    creativeId: number;
    creativeIdStr: string;
    creative: string;
    mediaType: 'V' | 'D'; // only known values are: D for display, and V for video
    programId: number;
    impPixelURL: string;
    instrPixelURL: string;
    status: 'ok' | 'punt' | 'error';
    // optionals
    language?: string;
    traceId?: string;
    statusDetails?: string[];
    // These are not in the docs
    creativeTemplateName: string;
    aaxMeasurementTrackers: {
        contains3P: 'true' | 'false';
        html: string;
    };
};

export const fetchGetAd = async (url: string): Promise<AAXGetAdResponse> => {
    const result = await fetch(url, { keepalive: true });
    if (result.ok) {
        return (await result.json()) as AAXGetAdResponse;
    } else {
        throw new Error(`Failed to fetch ad from ${url} due to ${result.status}`);
    }
};

export const ATF_URL_KEY = 'atf' as const;
export const PUBLISHER_JSON_KEY = 'pj' as const;
export const BTR_JSON_KEY = 'c' as const;

export type PublisherJSONObject = {
    measurementMethod?: string;
    ape_al?: boolean;
};

export const evaluateShouldAppendABPTag = ({ abpAcceptable = 'false', abpStatus = '-1' }) =>
    abpAcceptable === 'true' && (abpStatus === '1' || abpStatus === '-1');
