export const viewableTimeCalculatorState = {
    VIEWABLE: 'viewable',
    NOT_VIEWABLE: 'not_viewable',
};

/*
 * This is a class which is responsible for calculating the amount of time that
 * an ad has been considered 'viewable'.
 */
export class ViewableTimeCalculator {
    /**
     * @private {Number} _viewableStartTimeInMilliseconds - time when the
     *   most recent transition from not viewable to viewable state occurred
     */
    private _viewableStartTimeInMilliseconds = undefined;

    /**
     * @private {String} _currentState - the most recent state that was transitioned to
     */
    private _currentState = viewableTimeCalculatorState.NOT_VIEWABLE;

    private _isCurrentlyInNotViewableState = () => {
        return this._currentState === viewableTimeCalculatorState.NOT_VIEWABLE;
    };

    /**
     * @method transitionToViewableState
     * @desc [Internal] informs object to transition to the viewable state
     * @param {Number} currentTimeInMilliseconds
     */
    transitionToViewableState = (currentTimeInMilliseconds: any) => {
        /**
         * @method _isCurrentlyInNotViewableState
         * @desc [Internal] checks if object is in the not_viewable state
         * @private
         */

        if (this._isCurrentlyInNotViewableState()) {
            this._viewableStartTimeInMilliseconds = currentTimeInMilliseconds;
        }
        this._currentState = viewableTimeCalculatorState.VIEWABLE;
    };

    /**
     * @method transitionToNotViewableState
     * @desc [Internal] informs object to transition to the not_viewable state
     */
    transitionToNotViewableState = () => {
        this._currentState = viewableTimeCalculatorState.NOT_VIEWABLE;
    };

    /**
     * @method getViewableTimeInSeconds
     * @desc [Internal] Returns the amount of time that creative has been viewable according to a viewability standard
     * @param {Number} currentTimeInMilliseconds
     */
    getViewableTimeInSeconds = (currentTimeInMilliseconds: number) => {
        if (this._isCurrentlyInNotViewableState() || typeof this._viewableStartTimeInMilliseconds === 'undefined') {
            return 0;
        }
        return (currentTimeInMilliseconds - this._viewableStartTimeInMilliseconds) / 1000;
    };
}
